<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :label="$t('global.contact_component.form.form_labels.name')"
              color="dark"
              :dense="footer_version"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :label="$t('global.contact_component.form.form_labels.mail')"
              color="dark"
              :rules="emailRules"
              required
              :dense="footer_version"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :label="$t('global.contact_component.form.form_labels.company')"
              color="dark"
              :dense="footer_version"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              :label="$t('global.contact_component.form.form_labels.phone')"
              color="dark"
              :dense="footer_version"
              :rules="phoneRules"
              required
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              :label="$t('global.contact_component.form.form_labels.message')"
              outlined
              :placeholder="
                $t('global.contact_component.form.form_labels.message_placeholder')
              "
              color="dark"
              :rules="messageRules"
              required
              :dense="footer_version"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="12" class="text-right">
            <v-btn
              width="100%"
              color="primary"
              class="dark--text"
              @click="validate"
            >
              {{ $t("global.contact_component.form.form_labels.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-spacer />
  </div>
</template>

<script>
export default {
  props: {
    footer_version: { type: Boolean, default: false },
  },

  computed: {
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("global.contact_component.form.form_validation_msg.mail_required"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t("global.contact_component.form.form_validation_msg.mail_valid"),
      ];
    },

    messageRules() {
      return [
        (value) =>
          !!value ||
          this.$t(
            "global.contact_component.form.form_validation_msg.message_required"
          ),
        (value) =>
          (value && value.length >= 3) ||
          this.$t("global.contact_component.form.form_validation_msg.message_valid"),
      ];
    },

    phoneRules() {
      var regex_at_least_10_digits = /^!*(\d!*){10,}$/;
      return [
        (v) =>
          !!v ||
          this.$t("global.contact_component.form.form_validation_msg.phone_required"),
        (v) =>
          // replace(/[^0-9]/ig, "") --> remove all caracters except of digits
          regex_at_least_10_digits.test(v ? v.replace(/[^0-9]/gi, "") : "") ||
          this.$t("global.contact_component.form.form_validation_msg.phone_valid"),
      ];
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>